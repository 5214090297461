// eslint-disable-next-line object-curly-newline
import { isAfter, parseISO, subDays, subHours } from 'date-fns';
import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';
import {
  DocumentData, Query, QuerySnapshot, getCountFromServer
} from 'firebase/firestore';
import { functionsApi } from '../config/deliveryApi';

import {
  callCollection, doc, getDoc, getDocs, limit,
  orderBy, query, startAfter, startAt, endAt, subsidiaryCollection, updateDoc, where,
  callRejectionsCollection
} from '../config/firebase';
import api from '../config/functionsApi';
import StatusCall, { convertStatusToBrazilian } from '../models/enums/statusCall';
import Call, { CallCounter, CallReportCounter, HistoryBy } from '../models/interfaces/call';
import Dictionary from '../models/interfaces/dictionary';
import pushNotificationService from './pushNotificationService';
import subsidiaryService from './subsidiaryService';
import apiCallMicroservice, { BoazRemoteCallService } from '../config/callMicroserviceApi';
import SupabaseService from './supabase';

const supabaseService = new SupabaseService();
const countDestinations = async (subsidiaryId: string | undefined | null, fullPeriod: boolean) => {
  const maxDate = new Date();
  const minDate = fullPeriod ? new Date(maxDate.getFullYear(), 0, 1, 0, 0, 0) : new Date(maxDate.getFullYear(), maxDate.getMonth(), 1, 0, 0, 0);

  // Use index in firebase
  let q = query(callCollection, where('createdAt', '>=', minDate), where('complete', '==', true), where('status', '==', 'complete'));
  if (subsidiaryId) {
    q = query(q, where('subsidiary', '==', subsidiaryId));
  }
  const result = await getDocs(q);

  let destinationsTotal = 0;
  result.forEach((snap) => {
    const item = snap.data();
    destinationsTotal += item.destinations.length;
  });

  return destinationsTotal;
};

const countDestinationsWithMemoize = _.memoize(countDestinations, (...args) => _.values(args).join('_'));

export const statusAllowedToCompleteCall = ['ongoing', 'partialComplete'];

export default {
  async getAll(subsidiaryId: string | undefined | null, limitMax: number | null) {
    let q = query(callCollection, orderBy('createdAt', 'desc'));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    if (limitMax) {
      q = query(q, limit(limitMax));
    }

    return getDocs(q).then(async (querySnapshot) => {
      const result = querySnapshot.docs.map((snap) => {
        const model = snap.data() as Call;

        return {
          ...model,
          createdAt: snap.data().createdAt.toDate(),
          statusHtml: convertStatusToBrazilian(model.status),
          id: snap.id
        };
      });

      return result;
    });
  },

  getAllRealTimeStream(subsidiaryId: string | null | undefined, status: string | undefined, limitMax: number = 50) {
    const minDate = subHours(new Date(), 8);
    let q = query(callCollection, where('createdAt', '>=', minDate), orderBy('createdAt', 'desc'));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }
    if (status) {
      q = query(q, where('status', '==', status));
    }
    return query(q, limit(limitMax));
  },

  async getById(id: string) {
    const docRef = doc(callCollection, id);
    const result = await getDoc(docRef);
    if (!result.exists) throw new Error('Call not found');

    return result.data();
  },

  async getReport(id: string | null | undefined, startDate: Date, endDate: Date, clientId: string | null, driverId: string | null) {
    let subsidiaryId = id;
    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
    }

    let q = query(callCollection, where('subsidiaryTemp.id', '==', subsidiaryId), // TODO: Change to "subsidiary.id"
      where('complete', '==', true),
      where('status', '==', 'complete'));
    if (clientId) {
      q = query(q, where('origin.clientId', '==', clientId));
    }
    if (driverId) {
      q = query(q, where('driver.id', '==', driverId));
    }

    q = query(q, orderBy('createdAt'), startAt(startDate), endAt(endDate));

    return getDocs(q).then(async (querySnapshot) => {
      const result = querySnapshot.docs.map((snap: any) => {
        const model = snap.data() as Call;

        return {
          ...model,
          id: snap.id,
          statusHtml: convertStatusToBrazilian(model.status),
          createdAt: snap.data().createdAt.toDate()
        };
      });

      return result;
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
      return [];
    });
  },

  async getReportCounter(subId: string | null | undefined, startDate: Date, endDate: Date)
    : Promise<CallReportCounter[]> {
    let subsidiaryId = subId;
    if (subsidiaryId && uuidValidate(subsidiaryId)) {
      const newSub = await subsidiaryService.getByIdAsync(subsidiaryId);

      const subsidiariesByCNPJ = await getDocs(query(subsidiaryCollection, where('cnpj', '==', newSub.cnpj)));
      if (subsidiariesByCNPJ.size === 1) {
        subsidiaryId = subsidiariesByCNPJ.docs[0].id;
      }
    }

    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        dayIni: startDate.getDate(),
        dayFin: endDate.getDate(),
        monthIni: startDate.getUTCMonth() + 1,
        monthFin: endDate.getUTCMonth() + 1,
        year: endDate.getFullYear()
      }
    };

    const data: CallReportCounter[] = [];

    const response = await apiCallMicroservice.get('calls/callsbyrangedate', config);
    if (response?.data?.count > 0) {
      data.push({
        id: response?.data?.totals[0].subsidiaryid,
        value: response?.data?.totals[0].totalvaluecalls
      });
    }

    // GEt from Microservice calls

    // let q = query(callCollection, where('subsidiaryTemp.id', '==', subsidiaryId), // TODO: Change to "subsidiary.id"
    //   where('complete', '==', true),
    //   where('status', '==', 'complete'),
    //   where('createdAt', '>=', startDate),
    //   where('createdAt', '<=', endDate));
    // if (clientId) {
    //   q = query(q, where('origin.clientId', '==', clientId));
    // }
    // if (driverId) {
    //   q = query(q, where('driver.id', '==', driverId));
    // }
    // const result = await getDocs(q);

    // result.forEach((snap) => {
    //   const item = snap.data();
    //   data.push({
    //     id: snap.id,
    //     value: item.payment.value
    //   });
    // });

    return data;
  },

  async getAllReport(id: string | null | undefined, startDate: Date, endDate: Date, status: Dictionary[]) {
    let subsidiaryId = id;

    let q: Query;

    if (status.length === 1) {
      q = query(callCollection, where('status', '==', status[0].key),
        orderBy('createdAt'),
        startAt(startDate),
        endAt(endDate));
    } else {
      q = query(callCollection, where('status', 'in', status.map((x) => x.key)),
        orderBy('createdAt'),
        startAt(startDate),
        endAt(endDate));
    }

    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
      q = query(q, where('subsidiaryTemp.id', '==', subsidiaryId)); // TODO: Change to "subsidiary.id"
    }

    return getDocs(q).then(async (querySnapshot) => {
      const result = querySnapshot.docs.map((snap) => {
        const model = snap.data() as Call;

        return {
          ...model,
          id: snap.id,
          statusHtml: convertStatusToBrazilian(model.status),
          createdAt: snap.data().createdAt.toDate()
        };
      });

      return result;
    });
  },

  async count(subsidiaryId: string | undefined | null, fullPeriod = false) {
    return countDestinationsWithMemoize(subsidiaryId, fullPeriod);
  },

  async getCountTotalCanceled(days: number) {
    const maxDate = new Date();
    const minDate = subDays(new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0), days - 1);
    const c = query(callCollection, where('createdAt', '>=', minDate), where('status', '==', 'canceled'));
    const total = await getCountFromServer(c);
    return total?.data().count;
  },

  async deliveriesByMonthSupabse(subsidiaryId: string | undefined | null, timezone: string | null) {
    const data = await supabaseService.getCalls7DaysApi(subsidiaryId, timezone);
    return data;
  },

  async deliveriesByMonth(subsidiaryId: string | undefined | null, timezone: string | null) {
    const date = new Date();
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        monthini: 1,
        monthfin: date.getMonth() + 1,
        year: date.getFullYear(),
        timezone
      }
    };
    const response = await apiCallMicroservice.get('calls/deliveriesbymonth', config);
    return response.data;
  },

  async countCallsByClients(subsidiaryId: string | undefined | null, timezone: string | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        timezone
      }
    };
    const response = await apiCallMicroservice.get('calls/countcallsbyclients', config);
    return response.data;
  },

  async countCallsByDrivers(subsidiaryId: string | undefined | null, timezone: string | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        timezone
      }
    };
    const response = await apiCallMicroservice.get('calls/countcallsbydrivers', config);
    return response.data;
  },

  async sumcallsbymonth(subsidiaryId: string | undefined | null, timezone: string | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        timezone
      }
    };
    const response = await apiCallMicroservice.get('calls/sumcallsbymonth', config);
    return response.data;
  },

  async countCallsbymonth(subsidiaryId: string | undefined | null, timezone: string | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiaryId || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
        timezone
      }
    };
    const response = await apiCallMicroservice.get('calls/countcallsbymonth', config);
    return response.data;
  },

  prepareListFromFirestore(result: QuerySnapshot<DocumentData>) {
    const data: CallCounter[] = [];
    result.forEach((snap) => {
      const item = snap.data();
      // TODO: Remove after Aldenor fix this
      const pock = item.availableDrivers ?? {};
      data.push({
        id: snap.id,
        createdAt: item.createdAt.toDate(),
        status: item.status,
        categoryDriver: item.categoryDriver,
        destinations: item.destinations,
        device: item.device,
        availableDrivers: Object.values(pock),
        subsidiaryId: item.subsidiaryId,
        subsidiaryName: item.subsidiaryName
      });
    });
    return data;
  },

  prepareListFromJSONList(result: any[]) {
    const data: CallCounter[] = [];
    result.forEach((item) => {
      // TODO: Remove after Aldenor fix this
      const pock = item.availableDrivers ?? {};
      data.push({
        id: item.id,
        createdAt: new Date(item.createdat),
        status: item.status,
        categoryDriver: Number(item.drivercategory),
        destinations: Array.from(Array(item.deliveries).keys()),
        device: Number(item.device),
        availableDrivers: Object.values(pock),
        subsidiaryId: item.subsidiaryid,
        subsidiaryName: item.subsidiaryname
      });
    });
    return data;
  },

  async countLastDays(days: number, subsidiaryId: string | undefined | null, timezone: string | null) {
    // const maxDate = new Date();
    // const minDate = subDays(new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0), days - 1);

    // const auth = await BoazRemoteCallService.getNewToken();
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth?.token}`
    //   },
    //   params: {
    //     subsidiaryId: subsidiaryId || '',
    //     environment: process.env.REACT_APP_ADMIN_PROJECT_ID,
    //     dataini: `${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`,
    //     datefin: `${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`,
    //     timezone
    //   }
    // };

    // Use index in firebase
    // get canceled from firebase
    // let q = query(callCollection, where('createdAt', '>=', minDate), where('status', '==', 'canceled'));
    // if (subsidiaryId) {
    //   q = query(q, where('subsidiary', '==', subsidiaryId));
    // } else {
    //   q = query(q, where('subsidiary', '==', 0));
    // }

    // const result = await getDocs(q);
    // const data = this.prepareListFromFirestore(result);

    // firebase
    console.log(days);
    const calls = await this.deliveriesByMonthSupabse(subsidiaryId, timezone);
    const data = this.prepareListFromJSONList(calls);
    // const response = await apiCallMicroservice.get('calls/bybetweendate', config);
    // eslint-disable-next-line no-console
    // const resultFromApi = [];
    // if (response?.status === 200) {
    //   const dataResp = response?.data?.calls?.map((row: any) => ({
    //     ...row,
    //     createdAt: row.createdAt ? new Date(row.createdAt) : new Date()
    //   }));
    //   // resultFromApi.push(...dataResp);
    //   const allCalls = data.concat(dataResp);
    //   return _.sortBy(allCalls, (x) => x.createdAt);
    // }

    return _.sortBy(data, (x) => x.createdAt);
  },

  async fetchNextData(key: any, id: string | undefined | null, status: string | null | undefined, limitMax: number = 50) {
    let subsidiaryId = id;
    const maxDate = new Date();
    const minDate = subHours(maxDate, 12);
    let q = query(callCollection, where('createdAt', '>=', minDate), where('createdAt', '<=', maxDate), orderBy('createdAt', 'desc'));
    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    if (status) {
      q = query(q, where('status', '==', status));
    }
    q = query(q, startAfter(key), limit(limitMax));

    const result = await getDocs(q);

    const data: Call[] = [];
    let lastKey;
    result.forEach((snap) => {
      const item = snap.data();
      data.push({ ...item as Call, id: snap.id, createdAt: item.createdAt.toDate() });
      lastKey = snap.data().createdAt;
    });

    return { data, lastKey };
  },

  async getWithFilters(id: string | undefined | null, status: string | null | undefined) {
    let subsidiaryId = id;
    const maxDate = new Date();
    const minDate = subHours(maxDate, 12);
    let q = query(callCollection, where('createdAt', '>=', minDate), where('createdAt', '<=', maxDate), orderBy('createdAt', 'desc'));
    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    if (status) {
      q = query(q, where('status', '==', status));
    }

    return getDocs(q).then(async (querySnapshot) => {
      const result = querySnapshot.docs.map((snap) => {
        const model = snap.data() as Call;

        return {
          ...model,
          createdAt: snap.data().createdAt.toDate(),
          statusHtml: convertStatusToBrazilian(model.status),
          id: snap.id
        };
      });

      return result;
    });
  },

  async changeStatus(id: string, owner: HistoryBy, newStatus: string, daysAllowed: number) {
    const model = await this.getById(id);
    if (!model) throw new Error('Call not found');

    if (newStatus === 'complete' && !statusAllowedToCompleteCall.includes(model.status)) {
      throw new Error('Operation not allowed');
    }

    const cancelCompleteCall = newStatus === 'canceled' && model.status === 'complete';

    if (cancelCompleteCall) {
      const date = model.createdAt.toDate();
      if (!isAfter(date, subDays(new Date(), daysAllowed))) {
        throw new Error('Operation not allowed');
      }
    }

    const modelRef = doc(callCollection, id);

    const newStatusHistory = model.statusHistory;
    newStatusHistory.push({
      by: owner,
      date: new Date(), // getDateNow() ocorre erro porque o firebase não suporta esse método em uma array,
      status: newStatus
    });

    const dataToUpdate = {
      complete: true,
      status: newStatus,
      statusHistory: newStatusHistory,
      canceledAt: cancelCompleteCall ? parseISO(new Date().toISOString()) : null,
      remoteCanceledAt: null
    };

    return updateDoc(modelRef, dataToUpdate).then(async () => {
      if (model.driver) {
        let header = '?';
        let body = '?';
        if (newStatus === 'complete') {
          header = 'Entrega FINALIZADA manualmente';
          body = `Sua entrega do cliente ${model.origin.responsible} foi FINALIZADA pelo administrativo, qualquer dúvida pode entrar em contato conosco.`;
          const jsonBody = { callId: id };
          functionsApi.post('calls/sync', jsonBody);
        } else if (newStatus === 'canceled') {
          header = 'Entrega CANCELADA manualmente';
          body = `Sua entrega do cliente ${model.origin.responsible} foi CANCELADA pelo administrativo, qualquer dúvida pode entrar em contato conosco.`;
        }

        await pushNotificationService.sendNotificationToDriver(id, header, body, model.driver.id, model.driver.name).catch(() => {
          throw new Error('Notificacão não foi enviada para o entregador');
        });
      }
    });
  },

  async changeDriverAsync(id: string, driverId: string) {
    await api.patch(`calls/${id}/driver`, { driverId }).catch((e) => {
      throw new Error(e.response?.data?.message ?? 'Ops, aconteceu algo inesperado, por favor tente novamente');
    });
  },

  getDestinationsCount(calls: any[]): number {
    const destinationsTotal = calls!.length ? calls.map((row) => row.destinations.length).reduce((prev, next) => prev + next) : 0;
    return destinationsTotal;
  },

  getDestinationsValueSum(calls: any[]): any {
    const totals = {
      destinationsSumValue: 0,
      destinationsSumFee: 0
    };
    calls.forEach((x) => {
      const tariffValue = _.sumBy(x.destinations, 'tariff.value');
      const subsidiaryFeeValue = _.sumBy(x.destinations, 'tariff.subsidiaryFeeValue') ?? 0;
      const lastSubsidiaryFeeValueBack = x.hasBack ? x.destinations[x.destinations.length - 1].tariff.subsidiaryFeeValueBack ?? 0 : 0;
      const lastValueBack = x.hasBack ? x.destinations[x.destinations.length - 1].tariff.valueBack ?? 0 : 0;
      totals.destinationsSumValue += (tariffValue + lastValueBack); // Get value back from last destination
      totals.destinationsSumFee += (subsidiaryFeeValue + lastSubsidiaryFeeValueBack);
    });
    return totals;
  },

  async countRefuses(id: string) {
    const c = query(callRejectionsCollection, where('callId', '==', id));
    const total = await getCountFromServer(c);
    return total?.data().count;
  },

  async getSubsidiaries() {
    const q = query(subsidiaryCollection, where('disabledAt', '==', null), orderBy('description'));
    return getDocs(q).then(async (querySnapshot) => {
      const result: any[] = [];
      querySnapshot.forEach((snapshot: any) => {
        const model = snapshot.data();
        result.push({ id: snapshot.id, description: model.description });
      });
      return result;
    });
  },

  async countDestinations(subsidiary: string, month: number, year: number) {
    const start = new Date(year, month - 1, 1, 0, 0);
    const end = new Date(year, month, 1, 0, 0);
    const c = query(callCollection,
      where('subsidiary', '==', subsidiary),
      where('status', '==', StatusCall.Complete),
      orderBy('createdAt'),
      startAt(start),
      endAt(end));
    const total = await getCountFromServer(c);
    return total?.data().count;
  }

};
