/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import {
  isAfter, isBefore, subDays, addMinutes
} from 'date-fns';
import { validate as uuidValidate } from 'uuid';

import { getCountFromServer } from 'firebase/firestore';
import {
  doc,
  driverCollection,
  getDateNow,
  getDoc,
  getDocs,
  limit,
  listAll,
  onlineCollection,
  orderBy,
  query,
  ref,
  startAfter,
  storage,
  subsidiaryCollection,
  termCollection,
  updateDoc,
  where
} from '../config/firebase';
import Address from '../models/address';
import StatusDriver from '../models/enums/statusDriver';
import Driver, {
  DriverIsLockedPayload,
  DriverLockPayload,
  UpdateDriver
} from '../models/interfaces/driver';
import authService from './authService';
import { PushNotification } from '../models/interfaces/pushNotification';
import pushNotificationService from './pushNotificationService';
import { titleCase } from '../utils/stringHelper';
import { getGeocoding } from './geolocateService';
import DriverOnline from '../models/interfaces/driverOnline';
import api from '../config/functionsApi';
import apiCallMicroservice, {
  BoazRemoteCallService
} from '../config/callMicroserviceApi';
import subsidiaryService from './subsidiaryService';
import { ISimpleRow } from '../pages/dashboard/components/listSimpleCard';
import StringsService from './stringsService';
import { BoazTrackerService } from './trackService';
import { CategoryDriverText } from '../models/enums/categoryDriver';
import SearchService from './searchService';
// import SupabaseService from './supabase';
// const supabaseService = new SupabaseService();

export const getStatus = (disabled: boolean, document: any): StatusDriver => {
  if (disabled) return StatusDriver.Inactive;
  if (document) {
    if (document.approved == null) return StatusDriver.Pending;
    if (document.approved) return StatusDriver.Active;
    return StatusDriver.Denied;
  }
  return StatusDriver.Undefined;
};
const countTotal = async (subsidiaryId: string | undefined | null) => {
  let total;
  const q = query(driverCollection);
  const c = query(q);

  if (subsidiaryId) {
    total = await getCountFromServer(
      query(
        c,
        where('subsidiary', '==', subsidiaryId),
        where('disabledAt', '==', null)
      )
    );
  } else {
    total = await getCountFromServer(query(c, where('disabledAt', '==', null)));
  }
  return total?.data().count;
};
const countTotalWithMemoize = _.memoize(countTotal, (...args) => _.values(args).join('_'));
const boazTrackerService = new BoazTrackerService();
const searchService = new SearchService();

export default {
  async getAll(id: string | null | undefined, getAll = true, limitRecord = 2) {
    let subsidiaryId = id;
    let snapshot;
    let q = query(driverCollection);

    if (!getAll) {
      q = query(q, limit(limitRecord));
    }
    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        let queryAndConditions = query(
          subsidiaryCollection,
          where('cnpj', '==', sub.cnpj)
        );
        if (!getAll) queryAndConditions = query(queryAndConditions, limit(10));
        const snap = await getDocs(queryAndConditions);
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
      snapshot = await getDocs(
        query(q, where('subsidiary', '==', subsidiaryId))
      );
    } else {
      snapshot = await getDocs(q);
    }

    if (snapshot.empty) return [];

    const result = snapshot.docs.map((snap) => {
      const {
        name,
        address,
        document,
        category,
        email,
        pix,
        photoURL,
        disabledAt,
        createdAt,
        updatedAt
      } = snap.data();

      const pixInfo = pix ? pix.split('|')[1] : null;
      return {
        id: snap.id,
        name,
        email,
        pix: pixInfo,
        photoURL,
        category: category !== null ? category.toString() : '0',
        status: getStatus(disabledAt !== null, document),
        city: address
          ? `${address?.city} - ${address?.state}`
          : 'Não cadastrado',
        createdAt: createdAt?.toDate(),
        updatedAt: updatedAt?.toDate()
      };
    });
    return _.sortBy(result, (x) => x.name);
  },

  async getAllCount(id: string | null | undefined) {
    const subsidiaryId = id;
    let total;
    const q = query(driverCollection);
    const c = query(q);

    if (subsidiaryId) {
      total = await getCountFromServer(
        query(c, where('subsidiary', '==', subsidiaryId))
      );
    } else {
      total = await getCountFromServer(c);
    }

    return { total: total?.data().count };
  },

  async getPaginate(
    subsidiaryId: string | null | undefined,
    last: string | null | undefined,
    limitMax: number = 10
  ) {
    let snapshot;
    let q = query(driverCollection, orderBy('name', 'asc'), limit(limitMax));
    if (last) {
      q = query(q, startAfter(last));
    }
    if (subsidiaryId) {
      snapshot = await getDocs(
        query(q, where('subsidiary', '==', subsidiaryId))
      );
    } else {
      snapshot = await getDocs(q);
    }

    if (snapshot.empty) return [];

    const lastVisible = snapshot.docs[snapshot.docs.length - 1];
    const result = snapshot.docs.map((snap) => {
      const {
        name, address, document, email, photoURL, disabledAt
      } = snap.data();
      return {
        id: snap.id,
        name,
        email,
        photoURL,
        status: getStatus(disabledAt !== null, document),
        city: `${address.city} - ${address.state}`
      };
    });

    return {
      data: result,
      lastVisible
    };
  },

  async getById(id: string): Promise<Driver> {
    const result = await getDoc(doc(driverCollection, id));
    if (!result.exists()) throw new Error('Driver not found');

    const driver = result.data() as Driver;
    const userAuth = await authService.getByIdOrEmailAsync(id);
    if (userAuth === null) throw new Error('Firebase user not found');

    driver.emailVerified = userAuth.emailVerified;
    driver.disabled = driver.disabledAt !== null;
    driver.dtBirth = result.data()!.dtBirth?.toDate();
    if (result.data()!.document?.analyzedAt?.seconds) {
      driver.document.analyzedAt = new Date(
        result.data()!.document?.analyzedAt?.seconds * 1000
      );
    } else {
      driver.document.analyzedAt = result
        .data()!
        .document?.analyzedAt?.toDate();
    }
    const lockUntil = result.data()!.lockedUntil
      && isBefore(new Date(), result.data()!.lockedUntil.toDate())
      ? result.data()!.lockedUntil?.toDate()
      : null;
    driver.document.sendedAt = result.data()!.document?.sendedAt?.toDate();
    driver.disabledAt = result.data()!.disabledAt?.toDate();
    driver.createdAt = result.data()!.createdAt.toDate();
    driver.updatedAt = result.data()!.updatedAt?.toDate();
    driver.lockedUntil = lockUntil;
    driver.address = new Address(driver.address);
    driver.subsidiary = result.data()!.subsidiary;
    driver.cloudMessageToken = result.data()!.cloudMessageToken;
    return driver;
  },

  async unLockDriver(id: string) {
    const modelRef = doc(driverCollection, id);
    return updateDoc(modelRef, { lockedUntil: null });
  },

  async updateDevice(driverData: any) {
    const message: PushNotification = {
      tokens: [driverData.cloudMessageToken],
      title: 'update_local_data',
      message: '',
      image: '',
      data: JSON.stringify(driverData)
    };
    pushNotificationService.send(message);
  },

  async update(model: UpdateDriver, id: string) {
    const location = await getGeocoding(model.address);
    if (location === null) {
      throw new Error('Endereço inválido, verifique o CEP');
    }

    const modelRef = doc(driverCollection, id);
    const data = (await getDoc(modelRef)).data();
    const phoneNumber = data?.phoneNumber;
    const email = data?.email;
    const nameNormalize = titleCase(model.name);

    try {
      const driverData = {
        name: nameNormalize,
        cpf: model.cpf,
        dtBirth: model.dtBirth,
        email: model.email,
        phoneNumber: `+${model.phoneNumber?.replace(/[^0-9]/g, '')}`,
        category: model.category,
        rg: model.rg,
        address: { ...model.address, location },
        comments: model.comments ? model.comments : null,
        paymentTypes: model.paymentTypes?.map((x) => Number(x)),
        cloudMessageToken: model.cloudMessageToken
      };
      await updateDoc(modelRef, driverData);
      await this.updatePaymentTypesOnline(id, driverData.paymentTypes);
      this.updateDevice(driverData);
      return authService.updateEmailPhoneNumber(
        model.email,
        model.phoneNumber,
        id
      );
    } catch (err) {
      await updateDoc(modelRef, {
        email,
        phoneNumber: `+${phoneNumber?.replace(/[^0-9]/g, '')}`
      });
      return err;
    }
  },

  async changeStatus(id: string) {
    const modelRef = doc(driverCollection, id);
    const result = await getDoc(modelRef);
    if (!result.exists) throw new Error('Driver not found');

    const { disabledAt } = result.data() as Driver;
    const disabled = disabledAt === null || disabledAt === undefined;
    return authService.disableAsync(disabled, id).then((data) => {
      if (data.status === 204) {
        updateDoc(modelRef, {
          disabledAt: disabled ? getDateNow() : null
        });
      }
    });
  },

  async changeRegisterStatus(id: string, status: StatusDriver) {
    const modelRef = doc(driverCollection, id);
    const disabledAt = status === StatusDriver.Active ? null : getDateNow();
    return updateDoc(modelRef, { disabledAt, status });
  },

  async enableStatus(id: string) {
    const modelRef = doc(driverCollection, id);
    const disabled = false;
    return authService.disableAsync(disabled, id).then((data) => {
      if (data.status === 204) {
        return updateDoc(modelRef, { disabledAt: null });
      }
      return null;
    });
  },

  async disableStatus(id: string) {
    const modelRef = doc(driverCollection, id);
    const disabled = true;
    return authService.disableAsync(disabled, id).then((data) => {
      if (data.status === 204) {
        updateDoc(modelRef, {
          disabledAt: getDateNow()
        });
      }
    });
  },

  async getDocs(id: string) {
    const listRef = ref(storage, `drivers/${id}/documents`);
    const result = await listAll(listRef);

    return result.items;
  },

  async getTerms() {
    const q = query(termCollection, where('type', '==', 'contract'));
    const result = await getDocs(q);
    const terms: any[] = [];
    result.docs.forEach((row: any) => {
      terms.push({
        id: row.id,
        ...row.data(),
        description: StringsService.capitalizeFrase(row.data().header)
      });
    });
    return terms;
  },

  async updatePaymentTypesOnline(driverId: string, newPaymentTypes: number[]) {
    const paymentTypes = newPaymentTypes?.length ? newPaymentTypes : null;
    const modelRef = doc(onlineCollection, driverId);
    await updateDoc(modelRef, { paymentTypes });
  },

  async analyseDocs(
    driverId: string,
    status: boolean,
    currentUserId: string,
    justification: string
  ) {
    const result = await getDoc(doc(driverCollection, driverId));
    if (!result.exists) throw new Error('Driver not found');

    const model = result.data() as Driver;

    if (status) {
      model.status = StatusDriver.Active;
    }

    const modelRef = doc(driverCollection, driverId);
    const title = status
      ? 'Análise de documentos'
      : 'Seus documentos NÃO foram aprovados';
    const body = status
      ? 'Parabéns, seus documentos foram aprovados! Fique online agora mesmo e descubra como é fácil fazer entregas com a gente.'
      : justification;

    await updateDoc(modelRef, {
      status: model.status,
      document: {
        ...model.document,
        approved: status,
        analyzedBy: currentUserId,
        analyzedAt: getDateNow()
      }
    });

    const message: PushNotification = {
      tokens: [model.cloudMessageToken],
      title,
      message: body,
      image: '',
      data: status ? 'logout' : null
    };

    const resultPromisses = await Promise.all([
      pushNotificationService.send(message),
      pushNotificationService.sendNotificationToDriverAboutDocs(
        title,
        body,
        driverId,
        model.name
      )
    ]).catch(
      () => 'Documentos autorizados com sucesso, mas não foi possível enviar a notificação'
    );

    if (Array.isArray(resultPromisses)) return null;

    return resultPromisses;
  },

  sendNotification(
    title: string,
    text: string,
    driverId: string,
    driverName: string
  ) {
    return pushNotificationService.sendNotificationToDriverAboutDocs(
      title,
      text,
      driverId,
      driverName
    );
  },

  async count(subsidiaryId: string | undefined | null) {
    return countTotalWithMemoize(subsidiaryId);
  },

  async countByCurrentMonth(subId: string | undefined | null = null) {
    let subsidiaryId = subId;
    if (subsidiaryId && uuidValidate(subsidiaryId)) {
      const newSub = await subsidiaryService.getByIdAsync(subsidiaryId);

      const subsidiariesByCNPJ = await getDocs(
        query(subsidiaryCollection, where('cnpj', '==', newSub.cnpj))
      );
      if (subsidiariesByCNPJ.size === 1) {
        subsidiaryId = subsidiariesByCNPJ.docs[0].id;
      }
    }

    const maxDate = new Date();
    const minDate = new Date(
      maxDate.getFullYear(),
      maxDate.getMonth(),
      1,
      0,
      0,
      0
    );
    let q = query(driverCollection, where('createdAt', '>=', minDate));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }
    const result = await getDocs(q);

    return result.size;
  },

  removeFromOnlineDriversList(list: any[]) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const limitTime = element.stationary
        ? addMinutes(element.updatedAt, 180)
        : addMinutes(element.updatedAt, 90);
      const now = new Date();
      if (isAfter(now, limitTime)) {
        list.splice(index);
      }
    }
    return list;
  },

  updateOnlineDriversList(currentList: any[] | null | undefined, updated: any) {
    const index = currentList?.findIndex(
      (row: any) => row?.id === updated.data.driver.id
    );
    if (!index) return currentList;
    if (updated.action === 'delete') {
      if (index > -1) currentList?.splice(index);
    } else {
      const item = {
        ...updated.data,
        location: updated.data.position,
        createdAt: new Date(updated.data.createdAt),
        updatedAt: new Date(updated.data.updatedAt),
        insertedAt: new Date(),
        subsidiary: updated.data.subsidiary.id || updated.data.subsidiary
      };
      const preparedItems = this.prepareDriverList([item]);
      // console.log(preparedItems[0]);
      if (index < 0) {
        currentList?.push(...preparedItems);
      } else {
        // eslint-disable-next-line no-param-reassign, prefer-destructuring, no-lonely-if
        if (currentList) currentList[index] = preparedItems[0];
      }
      return currentList;
    }
    return currentList;
  },

  getAllOnlineSnapshot(subsidiaryId: string | null | undefined) {
    let q = query(onlineCollection);
    if (subsidiaryId) {
      q = query(q, where('subsidiary.id', '==', subsidiaryId));
    }

    return q;
  },

  async getOnlineFromRedis(
    subsidiaryId: string | null,
    userUid: string | null,
    latitude: number,
    longitude: number
  ) {
    const subsidiary = subsidiaryId || null;
    const resp: any = await boazTrackerService.getOnlineDrivers(
      subsidiary,
      userUid,
      latitude,
      longitude
    );
    if (resp?.status === 200) {
      const drivers: any = [];
      resp?.data?.response.forEach((driver: any) => {
        const item = {
          ...driver,
          location: driver.position,
          createdAt: new Date(driver.createdAt),
          updatedAt: new Date(driver.updatedAt),
          insertedAt: new Date(),
          subsidiary: subsidiaryId
        };
        drivers.push(item);
      });
      return drivers;
    }
    return [];
  },

  async getAllOnline(subsidiaryId: string | null | undefined) {
    let q = query(onlineCollection);
    if (subsidiaryId) {
      q = query(q, where('subsidiary.id', '==', subsidiaryId));
    }

    const snapshot = await getDocs(q);

    if (snapshot.empty) return [];

    const result = snapshot.docs.map((snap) => {
      const { driver, busy, location } = snap.data() as DriverOnline;

      return {
        category: driver.category,
        id: driver.id,
        name: driver.name,
        phoneNumber: driver.phone,
        photoURL: driver.photo,
        isBusy: busy,
        location,
        lat: location?.latitude,
        lon: location?.longitude
      };
    });

    return _.sortBy(result, (x) => x.name);
  },

  async getLastDays(days: number, subsidiaryId: string | undefined | null) {
    if (!subsidiaryId) return [];
    const maxDate = new Date();
    const minDate = subDays(
      new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate(),
        0,
        0,
        0
      ),
      days - 1
    );
    // Use index in firebase
    let q = query(driverCollection, where('createdAt', '>=', minDate));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    const result = await getDocs(q);

    const data: Driver[] = [];
    result.forEach((snap) => {
      const item = snap.data();
      data.push({
        ...(item as Driver),
        id: snap.id,
        createdAt: item.createdAt.toDate()
      });
    });

    return _.sortBy(data, (x) => x.createdAt);
  },

  async delete(id: string) {
    return api
      .delete(`drivers/${id}`)
      .then(() => true)
      .catch((e) => {
        if (e.response.status === 409) return false;

        throw new Error(
          e.response?.data?.message
            ?? 'Ops, aconteceu algo inesperado, por favor tente novamente'
        );
      });
  },

  async getTop(subsidiary: string | undefined | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiary || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID
      }
    };
    const response = await apiCallMicroservice.get('calls/topdrivers', config);
    const data: ISimpleRow[] = [];
    response.data.forEach((item: any) => {
      data.push({
        id: item.driverid,
        name: item.drivername,
        photoURL: item.photoURL,
        extraInfo: subsidiary ? '' : item.subsidiaryname
      });
    });

    return data;

    // const response = await supabaseService.getTopDrivers(subsidiary);
    // console.log(response);
    // const data: ISimpleRow[] = [];
    // response?.forEach((item: any) => {
    //   data.push({
    //     id: item.clientid,
    //     name: item.clientname,
    //     photoURL: item.photourl,
    //     extraInfo: subsidiary ? '' : item.subsidiaryname
    //   });
    // });
    // return data;
  },

  async getTopRefuser(subsidiary: string | undefined | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiary || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID
      }
    };
    const response = await apiCallMicroservice.get(
      'calls/topdriversrefuser',
      config
    );
    const data: ISimpleRow[] = [];
    response.data.forEach((item: any) => {
      data.push({
        id: item.byid,
        name: item.byname,
        photoURL: item.photourl,
        extraInfo: subsidiary
          ? `Recusou ${item.refuses} chamadas`
          : item.subsidiaryname
      });
    });
    return data;
  },

  prepareDriverList(drivers: any[]) {
    // eslint-disable-next-line no-shadow
    const result = drivers.map((doc: any) => {
      const driverOnline: DriverOnline = {
        ...doc,
        id: doc.driver.id,
        busy: doc.busy,
        createdAt: doc.createdAt,
        call: {
          client: {
            id: doc.currentCall.clientId,
            name: doc.currentCall.client
          },
          id: doc.currentCall.id,
          status: doc.currentCall.status
        },
        driver: {
          category: doc.driver.category,
          id: doc.driver.id,
          name: doc.driver.name,
          phone: doc.driver.phone,
          photo: doc.driver.photo ?? '?'
        },
        location: doc.location,
        subsidiary: doc.subsidiary,
        updatedAt: doc.updatedAt ?? doc.createdAt,
        stationary: doc.stationary
      };

      return {
        id: driverOnline.id,
        description: driverOnline.driver.name,
        category: CategoryDriverText[driverOnline.driver.category],
        html: `<div>${driverOnline.call.client.name ?? ''}</div>`,
        active: true,
        busy: driverOnline.busy,
        call: driverOnline.call,
        phoneNumber: driverOnline.driver.phone,
        photoURL: driverOnline.driver.photo,
        lat: driverOnline.location?.latitude ?? null,
        lng: driverOnline.location?.longitude ?? null,
        updatedAt: driverOnline.updatedAt,
        isLink: true,
        stationary: driverOnline.stationary,
        subsidiary: driverOnline?.subsidiary?.id
      };
    });
    return result;
  },

  async setPunishment(payload: DriverLockPayload) {
    return api.post<any>('drivers/lock', payload);
  },

  async getPunishment(payload: DriverIsLockedPayload) {
    return api.post<any>('drivers/islocked', payload);
  },

  async search(search: string, hitsPerPage: number, currentPage: number, filters: string | null = null) {
    const param = search?.length ? search : null;
    const indexName: string = process.env.REACT_APP_ADMIN_ALGOLIA_API_INDEX_DRIVER_NAME ? process.env.REACT_APP_ADMIN_ALGOLIA_API_INDEX_DRIVER_NAME : 'dev_driver_name_asc';
    console.log('Driver index name: ', indexName);
    // const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    let resp: any = null;
    resp = await searchService.libSearch(indexName, param, hitsPerPage, currentPage, filters);
    if (resp?.hits.length) {
      const results: any = resp?.hits.map((hit: any) => {
        const {
          name, category, email, pix, photoURL, phoneNumber, cpf, createdAt, disabledAt, updatedAt, lockedUntil, subsidiary
        } = hit;

        const address = {
          city: hit['address.city'],
          state: hit['address.state']
        };
        const document = hit.document.analyzedBy ? {
          analyzedBy: hit.document.analyzedBy,
          approved: hit.document.approved,
          sendedAt: hit.document.sendedAt ? new Date(hit.document.sendedAt) : null,
          analyzedAt: hit.document.analyzedAt ? new Date(hit.document.analyzedAt) : null
        } : null;
        return {
          id: hit.objectID,
          name: hit._highlightResult?.name?.matchedWords?.length ? hit._highlightResult.name.value : name,
          email: hit._highlightResult?.email?.matchedWords?.length ? hit._highlightResult.email.value : email,
          phoneNumber: hit._highlightResult?.phoneNumber?.matchedWords?.length ? hit._highlightResult.phoneNumber.value : phoneNumber,
          cpf: hit._highlightResult?.cpf?.matchedWords?.length ? hit._highlightResult.cpf.value : cpf,
          pix: pix ?? null,
          photoURL,
          category,
          document,
          address: hit['address.city'] ? address : null,
          disabledAt: disabledAt ? new Date(disabledAt) : null,
          createdAt: createdAt ? new Date(createdAt) : null,
          updatedAt: updatedAt ? new Date(updatedAt) : null,
          lockedUntil: lockedUntil ? new Date(lockedUntil) : null,
          subsidiary
        };
      });

      return {
        items: results,
        isStart: resp?.page === 0,
        isEnd: resp?.nbPages === resp?.page,
        totalHits: resp?.nbHits,
        currentPage: resp?.page,
        hitsPerPage: resp?.hitsPerPage,
        nbPages: resp?.nbPages
      };
    }
    return {
      items: [],
      isStart: true,
      isEnd: true,
      totalHits: 0,
      current: 0,
      currentPage: 0,
      nbPages: 0
    };
  }
};
