import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import MotorcycleIcon from '@mui/icons-material/TwoWheeler';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import MoneyTwoTone from '@mui/icons-material/MoneyTwoTone';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsIcon from '@mui/icons-material/Settings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import AttachMoney from '@mui/icons-material/AttachMoney';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import MapIcon from '@mui/icons-material/Map';
import AssignmentIcon from '@mui/icons-material/Assignment';

const iconsMap: any = {
  DashboardTwoToneIcon,
  PeopleIcon,
  MotorcycleIcon,
  SettingsApplicationsIcon,
  SettingsIcon,
  LocationCityIcon,
  MonetizationOnIcon,
  SwapVerticalCircleIcon,
  AttachMoney,
  AccountBalanceWallet,
  MapIcon,
  AssignmentIcon,
  MoneyTwoTone
};

export default [
  {
    label: 'Ýrea gerencial',
    content: JSON.parse(
      `[
        {
          "label": "Dashboards",
          "icon": "DashboardTwoToneIcon",
          "to": "/dashboard"
        },
        {
          "label": "Chamadas do dia",
          "icon": "SwapVerticalCircleIcon",
          "to": "/deliveries"
        },
        {
          "label": "Relatório de entregas",
          "icon": "AssignmentIcon",
          "to": "/deliveries-report"
        },
        {
          "label": "Mapa",
          "icon": "MapIcon",
          "to": "/map"
        },
        {
          "label": "Clientes",
          "icon": "LocationCityIcon",
          "to": "/companies"
        },
        {
          "label": "Entregadores",
          "icon": "MotorcycleIcon",
          "to": "/drivers"
        },
        {
          "label": "Financeiro",
          "icon": "AttachMoney",
          "to": "/reports/revenue"
        },
        {
          "label": "Faturamento",
          "icon": "AccountBalanceWallet",
          "to": "/reports/billing"
        },
        {
          "label": "Pré-Pago",
          "icon": "MoneyTwoTone",
          "to": "/reports/prepago"
        },
        {
          "label": "Usuários",
          "icon": "PeopleIcon",
          "to": "/users"
        },
        {
          "label": "Configurações",
          "icon": "SettingsApplicationsIcon",
          "content": [
            {
              "label": "Geral",
              "icon": "SettingsIcon",
              "description": "",
              "to": "/settings"
            },
            {
              "label": "Tarifas",
              "icon": "MonetizationOnIcon",
              "description": "",
              "to": "/settings/tariffs"
            },
            {
              "label": "Tarifas Dinâmica",
              "icon": "MonetizationOnIcon",
              "description": "",
              "to": "/settings/tariffs-dynamic"
            }
          ]
        }
      ]`,
      (key, value) => {
        if (key === 'icon') return iconsMap[value];
        return value;
      }
    )
  }
];
// {
//   "label": "Localidades",
//   "icon": "MonetizationOnIcon",
//   "description": "",
//   "to": "/settings/places"
// }
